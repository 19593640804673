import React, { useState, useEffect } from 'react'
import { StaticQuery, graphql } from "gatsby"
import { Flex, Box } from 'rebass'
import Menu from '../base/Menu'
import Link from '../base/Link'
import { getPartner } from '../../utils/partner'

const Header = () => {
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    setLoaded(true)
  }, [])

  const partner = getPartner()

  return (
    <StaticQuery query={graphql`
      query HeaderQuery
      {
        datoCmsHeader {
          logo {
            url
          }
          items {
            ... on DatoCmsAccountsLink {
              id
              title
              landlordTitle
              landlordServiceUrl
              landlordClaimsUrl
              ridecoverTitle
              ridecoverServiceUrl
              ridecoverClaimsUrl
              model {
                apiKey
              }
            }
            ... on DatoCmsExternalLink {
              id
              title
              url
              model {
                apiKey
              }
            }
            ... on DatoCmsBasicLink {
              id
              title
              path
              model {
                apiKey
              }
            }
            ... on DatoCmsProductsLink {
              id
              title
              links {
                ... on DatoCmsProduct {
                  id
                  name
                  slug
                  model {
                    apiKey
                  }
                }
                ... on DatoCmsCategory {
                  id
                  name
                  slug
                  products {
                    id
                    slug
                    name
                  }
                  model {
                    apiKey
                  }
                }
              }
              model {
                apiKey
              }
            }
          }
        }
      }
    `}
    render={data => {
      // exclude partner menu items for partner branded site
      const menu = partner
        ? data.datoCmsHeader.items.filter(({ path = '' }) => !path.match('partner'))
        : data.datoCmsHeader.items

      return (
        <>
          <Flex variant='header'>
            <Link to="/" mx={[2, 3]}>
              <img src={data.datoCmsHeader.logo.url} alt="ShareCover" />
            </Link>
            {loaded && partner && (
              <>
                {'+'}
                <Link to="/" mx={[2, 3]}>
                  <img src={partner.logo.url} alt={partner.name} />
                </Link>
              </>
            )}
            <Box flex={1} />
            <Menu items={menu} />
          </Flex>
        </>
      )
    }}
    />
  )
}

export default Header
