import React from 'react'
import { Box, Heading } from 'rebass'
import Link from './Link'
import { modelSlugToRoute } from '../../utils/links'

const headingSize = 2

const MenuDrawerLink = props => {
    switch (props.model.apiKey) {
        case 'basic_link':
            return (
                <Link
                    key={props.id}
                    to={props.path}
                    p={[2, 3]}
                    fontSize={headingSize}
                >
                    {props.title}
                </Link>
            )

        case 'external_link':
            return (
                <Link
                    key={props.id}
                    href={props.url}
                    as="a"
                    p={[2, 3]}
                    fontSize={headingSize}
                >
                    {props.title}
                </Link>
            )

        case 'products_link':
            return (
                <Box p={[2, 3]}>
                    <Heading fontSize={headingSize}>{props.title}</Heading>
                    {props.links.map(link => {
                        switch (link.model.apiKey) {
                            case 'product':
                                return (
                                    <Box key={link.id} flex={1} mt={2}>
                                        <Heading variant="dropdown">{link.name}</Heading>
                                        <Link
                                            to={modelSlugToRoute(link.model.apiKey, link.slug)}
                                            sx={{ display: 'block', py: 1 }}
                                        >
                                            {link.name}
                                        </Link>
                                    </Box>
                                )

                            case 'category':
                                return (
                                    <Box key={link.id} flex={1} mt={2}>
                                        <Heading variant="dropdown">{link.name}</Heading>
                                        {link.products.map(product => (
                                            <Link
                                                key={product.id}
                                                to={modelSlugToRoute('product', product.slug)}
                                                sx={{ display: 'block', py: 1 }}
                                            >
                                                {product.name}
                                            </Link>
                                        ))}
                                        <Box sx={{ height: 2, bg: 'lightGrey', my: 1 }} />
                                        <Link
                                            to={modelSlugToRoute('category', link.slug)}
                                            sx={{ display: 'block', py: 1, mb: -1 }}
                                        >
                                            Compare products
                                        </Link>
                                    </Box>
                                )

                            default:
                                return null
                        }
                    })}
                </Box>
            )

        case 'accounts_link':
            return (
                <Box p={[2, 3]}>
                    <Heading fontSize={headingSize}>{props.title}</Heading>
                    <Box mt={2}>
                        <Heading variant="dropdown">{props.landlordTitle}</Heading>
                        <Link
                            as="a"
                            href={props.landlordServiceUrl}
                            sx={{ display: 'block', py: 1 }}
                        >
                            Login
                        </Link>
                        <Link
                            as="a"
                            href={props.landlordClaimsUrl}
                            sx={{ display: 'block', py: 1 }}
                        >
                            Claims
                        </Link>
                    </Box>
                    <Box mt={2}>
                        <Heading variant="dropdown">{props.ridecoverTitle}</Heading>
                        <Link
                            as="a"
                            href={props.ridecoverServiceUrl}
                            sx={{ display: 'block', py: 1 }}
                        >
                            Login
                        </Link>
                        <Link
                            as="a"
                            href={props.ridecoverClaimsUrl}
                            sx={{ display: 'block', py: 1 }}
                        >
                            Claims
                        </Link>
                    </Box>
                </Box>
            )

        default:
            return null
    }
}

export default MenuDrawerLink
