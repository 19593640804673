import React from 'react'
import { Flex, Box, Heading } from 'rebass'
import { StaticQuery, graphql } from "gatsby"
import Link from '../base/Link'
import TopIcon from '../../assets/icons/chevron-up.svg'
import { modelSlugToRoute } from '../../utils/links'

const spacing = [0, 1]

const Menu = ({ title, children, ...props }) => (
  <Box sx={{ mx: spacing, mb: 4 }} {...props}>
    <Heading sx={{ fontSize: [1, 1] }}>{title}</Heading>
    {[...children].map((link, i) => React.cloneElement(
      link,
      { sx: { display: 'block', my: 2 }, key: i }
    ))}
  </Box>
)

const Footer = () => (
  <StaticQuery query={graphql`
    query FooterQuery
    {
      datoCmsFooter {
        logo {
          url
        }
        links {
          title
          links {
            ... on DatoCmsProduct {
              id
              name
              model {
                apiKey
              }
              slug
            }
            ... on DatoCmsCategory {
              id
              name
              model {
                apiKey
              }
              slug
            }
          }
        }
        legalNode {
          childMarkdownRemark {
            html
          }
        }
      }
      allDatoCmsPage {
        edges {
          node {
            id
            slug
            name
          }
        }
      }
    }
  `}
  render={data => (
    <Box variant='footer'>
      <Flex flexDirection={['column', 'column', 'row']} justifyContent="space-between">
        <Box sx={{ mr: spacing, mb: [2, 2, 0] }}>
          <Link to="/" className="logo">
            <img src={data.datoCmsFooter.logo.url} alt="ShareCover" />
          </Link>
        </Box>
        <Menu title="About">
          <Link to="/contact">Contact Us</Link>
          <Link to="/partners">Partners</Link>
          <Link to="/faqs">FAQs</Link>
          <Link to="/blog">Blog</Link>
          <Link to="/product-disclosures">PDS &amp; KFS</Link>
        </Menu>
        <Box>
          {data.datoCmsFooter.links.map(group =>
            <Menu key={group.title} title={group.title}>
              {group.links.map(link =>
                <Link
                  key={link.id}
                  to={modelSlugToRoute(link.model.apiKey, link.slug)}
                >
                  {link.name}
                </Link>
              )}
            </Menu>
          )}
        </Box>
        <Menu title="Legal">
          {data.allDatoCmsPage.edges.map(({ node: page }) =>
            <Link key={page.id} to={`/${page.slug}`}>
              {page.name}
            </Link>
          )}
        </Menu>
        <Box>
          <Link
            as="button"
            onClick={() => window.scroll(0, 0)}
            sx={{ background: 'none', border: 'none', cursor: 'pointer' }}
          >
            <img src={TopIcon} alt="Scroll to top" />
          </Link>
        </Box>
      </Flex>
      <Box
        mt={[4, 4, 6]}
        dangerouslySetInnerHTML={{
          __html: data.datoCmsFooter.legalNode.childMarkdownRemark.html,
        }}
      />
    </Box>
    )}
  />
)

export default Footer
