module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://sharecover.co.nz"},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/opt/app/src/components/root/layout.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"ShareCover","short_name":"ShareCover","start_url":"/","background_color":"#fff","theme_color":"#EF801D","display":"standalone","icon":"src/assets/logo-glyph-orange.svg"},
    },{
      plugin: require('../node_modules/gatsby-plugin-sentry/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://376e46f359aa4cc390f26edb3aee60a4@sentry.io/1763164","environment":"prod","enabled":true},
    }]
