import { Component } from 'react'
import PropTypes from 'prop-types'
import onClickOutside from 'react-onclickoutside'

class OutsideClick extends Component {
  handleClickOutside (event) {
    this.props.onClickOutside(event)
  }

  render () {
    return this.props.children
  }
}

OutsideClick.defaultProps = {
  onClickOutside: PropTypes.func.isRequired,
  children: PropTypes.any.isRequired
}

export default onClickOutside(OutsideClick)
