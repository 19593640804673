import { get, set, remove } from "local-storage"

export const setReferrer = data => {
    set('referrer', data)
    remove('partnerWelcomed')
}
export const getReferrer = () => get('referrer')

export const markPartnerWelcomed = () => set('partnerWelcomed', true)
export const showPartnerWelcome = () => get('referrer')
    && get('referrer').partner
    && !get('partnerWelcomed')
