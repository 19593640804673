import React from 'react'
import { Flex, Box, Heading } from 'rebass'
import Dropdown from './Dropdown'
import Link from './Link'
import { modelSlugToRoute } from '../../utils/links'

const MenuLink = props => {
    switch (props.model.apiKey) {
        case 'basic_link':
            return (
                <Link
                    key={props.id}
                    to={props.path}
                    p={[2, 3]}
                >
                    {props.title}
                </Link>
            )

        case 'external_link':
            return (
                <Link
                    key={props.id}
                    href={props.url}
                    as="a"
                    p={[2, 3]}
                >
                    {props.title}
                </Link>
            )

        case 'products_link':
            return (
                <Dropdown title={props.title}>
                    <Flex m={-2} flexDirection={['column', 'column', 'row']}>
                        {props.links.map(link => {
                            switch (link.model.apiKey) {
                                case 'product':
                                    return (
                                        <Box key={link.id} flex={1} m={2} sx={{ minWidth: 200 }}>
                                            <Heading variant="dropdown">{link.name}</Heading>
                                            <Link
                                                to={modelSlugToRoute(link.model.apiKey, link.slug)}
                                                sx={{ display: 'block', py: 1 }}
                                            >
                                                {link.name}
                                            </Link>
                                        </Box>
                                    )

                                case 'category':
                                    return (
                                        <Box key={link.id} flex={1} m={2} sx={{ minWidth: 200 }}>
                                            <Heading variant="dropdown">{link.name}</Heading>
                                            {link.products.map(product => (
                                                <Link
                                                    key={product.id}
                                                    to={modelSlugToRoute('product', product.slug)}
                                                    sx={{ display: 'block', py: 1 }}
                                                >
                                                    {product.name}
                                                </Link>
                                            ))}
                                            <Box sx={{ height: 2, bg: 'lightGrey', my: 1 }} />
                                            <Link
                                                to={modelSlugToRoute('category', link.slug)}
                                                sx={{ display: 'block', py: 1, mb: -1 }}
                                            >
                                                Compare products
                                            </Link>
                                        </Box>
                                    )

                                default:
                                    return null
                            }
                        })}
                    </Flex>
                </Dropdown>
            )

        case 'accounts_link':
            return (
                <Dropdown title={props.title}>
                    <Flex m={-2} flexDirection="column">
                        <Box m={2}>
                            <Heading variant="dropdown">{props.landlordTitle}</Heading>
                            <Link
                                as="a"
                                href={props.landlordServiceUrl}
                                sx={{ display: 'block', py: 1 }}
                            >
                                My Account
                            </Link>
                            <Link
                                as="a"
                                href={props.landlordClaimsUrl}
                                sx={{ display: 'block', py: 1 }}
                            >
                                Claims
                            </Link>
                        </Box>
                        <Box m={2}>
                            <Heading variant="dropdown">{props.ridecoverTitle}</Heading>
                            <Link
                                as="a"
                                href={props.ridecoverServiceUrl}
                                sx={{ display: 'block', py: 1 }}
                            >
                                My Account
                            </Link>
                            <Link
                                as="a"
                                href={props.ridecoverClaimsUrl}
                                sx={{ display: 'block', py: 1 }}
                            >
                                Claims
                            </Link>
                        </Box>
                    </Flex>
                </Dropdown>
            )

        default:
            return null
    }
}

export default MenuLink
