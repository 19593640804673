import React, { useState, useEffect } from 'react'
import { Flex, Box, Heading, Image, Link } from 'rebass'
import { showPartnerWelcome, markPartnerWelcomed } from '../../utils/referrer'
import { getPartner } from '../../utils/partner'

const PartnerModal = () => {
    const partner = getPartner()
    const [shown, setShown] = useState(showPartnerWelcome())
    const isShown = partner && shown

    useEffect(() => {
        document.body.style.overflow = isShown ? 'hidden' : 'unset'

        return () => {
            document.body.style.overflow = 'unset'
        }
    }, [isShown])

    const close = () => {
        markPartnerWelcomed()
        setShown(false)
    }

    if (!isShown)
        return null

    return (
        <Box variant='modal'>
            <Flex
                sx={{ maxWidth: 500 }}
                flexDirection='column'
            >
                <Flex
                    flex={1}
                    width={1/2}
                >
                    <Image src={partner.logo.url} />
                </Flex>
                <Heading
                    flex={3}
                    as="h3"
                    variant='heading'
                    fontWeight={500}
                    lineHeight={1.6}
                    my={[2, 4]}
                >
                    {partner.name} knows insurance is important for you, so we have teamed up to provide you peace of mind.
                </Heading>
                <Link onClick={close} variant="button">
                    Continue
                </Link>
            </Flex>
        </Box>
    )
}

export default PartnerModal
