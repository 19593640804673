import React, { useState, useRef, useEffect, useLayoutEffect } from 'react'
import { Box, Button } from 'rebass'
import { globalHistory } from '@reach/router'
import OutsideClick from './OutsideClick'

const Dropdown = ({ title, children }) => {
    const [show, setShow] = useState(false)
    const [sx, setSx] = useState({})
    const inputEl = useRef(null)

    useEffect(() => globalHistory.listen(() => setShow(false)), [])

    useLayoutEffect(() => {
        if (!show)
            return

        const bounding = inputEl.current && inputEl.current.getBoundingClientRect()

        if (bounding && bounding.left < 0) {
            setSx({ left: [1, 3], transform: 'none', right: [1, 'auto'] })
        }

        if (bounding && bounding.right > (window.innerWidth || document.documentElement.clientWidth)) {
            setSx({ right: [1, 3], transform: 'none', left: [1, 'auto'] })
        }
    }, [show])

    return <Box sx={{ position: ['initial', 'initial', 'relative'] }}>
        <Button
            variant="link"
            p={[2, 3]}
            onClick={() => setShow(!show)}
        >
            {title}
        </Button>

        {show && (
            <Box
                variant="dropdown"
                sx={sx}
                ref={inputEl}
            >
                <OutsideClick onClickOutside={() => setShow(false)}>
                    {children}
                </OutsideClick>
            </Box>
        )}
    </Box>
}

export default Dropdown
