export const modelSlugToRoute = (model, slug) => {
  switch (model) {
    case 'product':
      return `/products/${slug}`

    case 'category':
      return `/categories/${slug}`

    case 'faq':
      return `/faqs/${slug}`

    case 'blog':
      return `/${model}/${slug}`

    default:
      return slug
  }
}
