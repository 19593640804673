import React from 'react'
import { Link as RouterLink } from 'gatsby'
import { Link as RebassLink } from 'rebass'
import { getReferrer } from '../../utils/referrer'

const Link = RouterLink

Link.defaultProps = {
  activeClassName: 'active'
}

function updateUrlParameter(uri, key, value) {
  // remove the hash part before operating on the uri
  var i = uri.indexOf('#');
  var hash = i === -1 ? ''  : uri.substr(i);
  uri = i === -1 ? uri : uri.substr(0, i);

  var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
  var separator = uri.indexOf('?') !== -1 ? "&" : "?";

  if (!value) {
    // remove key-value pair if value is empty
    uri = uri.replace(new RegExp("([?&]?)" + key + "=[^&]*", "i"), '');
    if (uri.slice(-1) === '?') {
      uri = uri.slice(0, -1);
    }
    // replace first occurrence of & by ? if no ? is present
    if (uri.indexOf('?') === -1) uri = uri.replace(/&/, '?');
  } else if (uri.match(re)) {
    uri = uri.replace(re, '$1' + key + "=" + value + '$2');
  } else {
    uri = uri + separator + key + "=" + value;
  }
  return uri + hash;
}

const WrappedLink = ({ transfer, ...props }) => {
  if (transfer && props.href) {
    const data = getReferrer()

    if (data) {
      Object.entries(data || {}).forEach(([ key, value ]) => {
        props.href = updateUrlParameter(props.href, key, value)
      })
    }
  }

  // replace `sharecover.xxx` links to target env's domain
  if (props.href && props.href.match(/sharecover\.[a-z]{2,3}\//)) {
    const targetTld = process.env.DOMAIN_NAME.split('sharecover').pop() // .xyz

    props.href = props.href.replace(/sharecover\.[a-z]{2,3}/, `sharecover${targetTld}`)
  }

  return (
    <RebassLink
      as={Link}
      {...props}
    />
  )
}

WrappedLink.defaultProps = {
  transfer: false
}

export default WrappedLink
