import React from 'react'
import PropTypes from 'prop-types'
import { Image } from 'rebass'
import styled from '@emotion/styled'
import OutsideClick from './OutsideClick'
import CloseIcon from '../../assets/icons/cross.svg'

export const Close = styled(Image)`
  position: absolute;
  top: 20px;
  right: 20px;

  width: 32px;
  height: 32px;
  cursor: pointer;

  border-radius: 50%;

  transition: background-color 120ms linear;

  :hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`

export const Panel = styled.div`
  width: 360px;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  background-color: ${props => props.theme.colors.white};
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.2);
  z-index: 1000;
  overflow-y: auto;
  display: flex;
  flex-direction: column;

  will-change: transform;
  transition: transform 0.3s ease-in-out;
  transform: translateX(120%);

  ${props => props.isOpen && `
    transform: translateX(0px);
  `}

  form {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
`

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 999;

  will-change: opacity, visibility;
  transition: opacity 0.3s ease-in-out;
  opacity: 0;
  visibility: hidden;

  ${props => props.isOpen && `
    opacity: 1;
    visibility: visible;
  `}
`

const Drawer = ({ isOpen, onClose, children }) => (
  <>
    <OutsideClick onClickOutside={onClose}>
      <Panel isOpen={isOpen}>
        <Close onClick={onClose} src={CloseIcon} />
        {children}
      </Panel>
    </OutsideClick>
    <Overlay isOpen={isOpen} />
  </>
)

Drawer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.any
}

Drawer.defaultProps = {
  children: null
}

export default Drawer
