import React from 'react'
import PropTypes from 'prop-types'
import { ThemeProvider } from 'emotion-theming'
import { Global } from '@emotion/core'
import { StaticQuery, graphql } from "gatsby"
import { HelmetDatoCms } from 'gatsby-source-datocms'
import Header from '../base/Header'
import Footer from '../base/Footer'
import globalStyles from './globalStyles'
import theme from './theme'
import PartnerModal from '../base/PartnerModal'

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query LayoutQuery
      {
        datoCmsSite {
          locale
          globalSeo {
            siteName
          }
          faviconMetaTags {
            ...GatsbyDatoCmsFaviconMetaTags
          }
        }
        datoCmsHomepage {
          seoMetaTags {
            ...GatsbyDatoCmsSeoMetaTags
          }
        }
      }
    `}
    render={data => (
      <ThemeProvider theme={theme}>
        <Global styles={globalStyles} />
        <HelmetDatoCms
          favicon={data.datoCmsSite.faviconMetaTags}
          seo={data.datoCmsHomepage.seoMetaTags}
          htmlAttributes={{
            lang: data.datoCmsSite.locale
          }}
        />
        <Header />
        {children}
        <Footer />
        <PartnerModal />
      </ThemeProvider>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.object
}

export default Layout
