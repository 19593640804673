import React, { useState, useEffect } from 'react'
import { Box, Button, Image } from 'rebass'
import { globalHistory } from '@reach/router'
import Drawer from '../base/Drawer'
import MenuLink from '../base/MenuLink'
import MenuDrawerLink from '../base/MenuDrawerLink'
import ListIcon from '../../assets/icons/list.svg'

const Menu = ({ items }) => {
    const [show, showMenu] = useState(false)

    useEffect(() => globalHistory.listen(() => showMenu(false)), [])

    return (
        <>
            {/* mobile */}
            <Box sx={{ display: ['block', 'none'] }}>
                <Button
                    onClick={() => showMenu(!show)}
                    mx={[2, 3]}
                    variant="icon"
                >
                    <Image src={ListIcon} alt="Menu" />
                </Button>

                <Drawer
                    onClose={() => showMenu(false)}
                    isOpen={show}
                >
                    {items.map(item => <MenuDrawerLink key={item.id} {...item} />)}
                </Drawer>
            </Box>

            {/* desktop */}
            <Box sx={{ display: ['none', 'flex'] }}>
                {items.map(item => <MenuLink key={item.id} {...item} />)}
            </Box>
        </>
    )
}

export default Menu
